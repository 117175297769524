<template>
  <b-container id="textracts">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <h1>Textracts</h1>
    <b-table
      :items="textracts"
      :fields="fields"
      :busy="busy"
      sort-by="created"
      sort-desc
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #table-busy>
        <Busy primary text="Loading..." />
      </template>
      <template #cell(organisationId)="row">
        {{ orgShortName(row.item.orgName) }}
      </template>
      <template #cell(created)="row">{{ formatDateTimeToMin(row.value) }}</template>
      <template #cell(startedJobs)="row">
        {{ row.value ? `${row.item.completedJobs} / ${row.value}` : 0 }}
      </template>
      <template #cell(state)="row">
        <b-badge :variant="stateBadgeVariant(row.value)">{{
          row.value == "RUNNING" ? "IN PROGRESS" : row.value
        }}</b-badge>
      </template>

      <template #cell(showNumbers)="row">
        <b-button
          size="sm"
          class="mr-2 border text-nowrap"
          variant="light"
          :to="{ name: 'textract-result', params: { organisationId: row.item.organisationId } }"
        >
          {{ row.item.mobileNumbersFound || row.item.landlineNumbersFound ? "Show" : "No" }} Results
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-if="textracts.length > perPage"
      align="right"
      :total-rows="textracts.length"
      :per-page="perPage"
      v-model="currentPage"
      :first-text="'\u00AB'"
      :prev-text="'\u2039'"
      :next-text="'\u203A'"
      :last-text="'\u00BB'"
      :ellipsis-text="'\u2026'"
    />
  </b-container>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";

export default {
  name: "Textracts",
  components: { Busy },
  data() {
    return {
      title: "Textracts",
      busy: false,
      textracts: [],
      fields: [
        { key: "showNumbers", label: "" },
        {
          key: "organisationId",
          label: "Organisation"
        },
        {
          key: "created",
          label: "Start time",
          sortable: true
        },
        {
          key: "createdByUserEmail",
          label: "Started by"
        },
        {
          key: "startedJobs",
          label: "Attachments analysed"
        },
        {
          key: "mobileNumbersFound",
          label: "Mobile no. found"
        },
        {
          key: "landlineNumbersFound",
          label: "Landline no. found"
        },
        {
          key: "state",
          label: ""
        }
      ],
      currentPage: 1,
      perPage: 50
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    formatCoboldCase: FormatHelper.formatCoboldCase,
    formatDateTimeToMin: FormatHelper.formatDateTimeToMin,
    orgShortName: FormatHelper.orgShortName,
    async fetchData() {
      this.busy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}textracts/summaries`)
        .then((response) => {
          Console.log(response);
          this.textracts = response.data.textractResults;
        })
        .catch((e) => {
          Console.error(e);
          this.showToast("There was an issue fetching textract data.", "warning");
        })
        .finally(() => (this.busy = false));
    },
    stateBadgeVariant(state) {
      switch (state) {
        case "COMPLETED":
          return "success";
        case "RUNNING":
          return "primary";
        case "ERROR":
          return "danger";
      }
    },
    showToast(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#textracts {
  @include badge-styles;
  .container {
    max-width: 1500px;
  }
  .number-col {
    max-width: 7rem;
    white-space: nowrap;
    border-radius: 1rem;
    .copy-btn {
      max-height: 25px;
      width: 95%;
      padding-top: 0em;
    }
    &:hover {
      & > .number {
        display: none;
      }
      & > .copy-btn {
        display: block !important;
      }
    }
  }
  .count-col {
    max-width: 6.5rem;
    .badge {
      width: 5rem;
    }
  }
  .supplier-col {
    overflow: hidden;
    white-space: nowrap;
    .badge-light {
      color: $color-primary-blue !important;
      background: white;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 8rem;
      margin-left: 0.5rem;
      margin-bottom: -0.5rem;
    }
  }
  .expand-col {
    position: absolute;
    right: 1rem;
    transform: rotate(180deg);
  }
  .list-group-item {
    background: $color-grey-lighter5 !important;
  }
  .number-locations {
    padding: 0.2rem !important;
    margin-top: 1rem !important;
    background: white;
    border-radius: 6px;
    cursor: default;
    .badge {
      margin-top: -10px !important;
    }
  }
}
</style>
